import { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { createClient } from '@supabase/supabase-js';
import { Analytics } from "@vercel/analytics/react"

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

function App() {
  const [displayText, setDisplayText] = useState('');
  const [displayAuthor, setDisplayAuthor] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [showUI, setShowUI] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    submitterName: '',
    quote: '',
    author: '',
    username: '',
    socialLink: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isBouncing, setIsBouncing] = useState(false);

  const quote = "Tomorrow is another day to say hello.";
  const author = "Lebron James";
  const typingSpeed = 25;

  useEffect(() => {
    // Fetch quote from Supabase
    const fetchQuote = async () => {
      const { data, error } = await supabase
        .from('CurrentQuote')
        .select('*')
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching quote:', error);
        return;
      }

      setQuoteData(data);
    };

    fetchQuote();
  }, []);

  useEffect(() => {
    if (quoteData) {
      typeQuote(quoteData.quote, quoteData.quote_author);
    }
  }, [quoteData]);

  const typeQuote = (quote, author) => {
    setDisplayText('');
    setDisplayAuthor('');
    
    let quoteInterval;
    let authorInterval;
    
    const quotedText = `"${quote}"`;
    
    let currentIndex = 0;
    quoteInterval = setInterval(() => {
      if (currentIndex <= quotedText.length) {
        setDisplayText(quotedText.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(quoteInterval);
        let authorIndex = 0;
        authorInterval = setInterval(() => {
          if (authorIndex <= author.length) {
            setDisplayAuthor(authorIndex === 0 ? '' : `- ${author.slice(0, authorIndex)}`);
            authorIndex++;
          } else {
            clearInterval(authorInterval);
            setIsTyping(false);
            setShowUI(true);
          }
        }, typingSpeed);
      }
    }, typingSpeed);

    // Clean up both intervals
    return () => {
      clearInterval(quoteInterval);
      clearInterval(authorInterval);
    };
  };

  // Calculate days since November 10th, 2023
  const calculateDaysSince = () => {
    const startDate = new Date('2024-11-11T09:00:00-05:00'); // EST time, 9am
    const today = new Date();
    // Convert to EST
    const estTime = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const diffTime = Math.abs(estTime - startDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include the start day
    return diffDays;
  };

  const getCurrentDate = () => {
    return new Date().toLocaleDateString('en-US', { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  const validateUrl = (url) => {
    if (!url) return true; // Optional field
    try {
      const urlObj = new URL(url);
      // Accept http, https, and www
      return urlObj.protocol === 'http:' || 
             urlObj.protocol === 'https:' || 
             url.startsWith('www.');
    } catch {
      // If URL parsing fails, check if it starts with www.
      return url.startsWith('www.');
    }
  };

  const validateLength = (text) => {
    return text.length >= 2 && text.length <= 500;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.username) {
      console.log('Bot submission detected');
      setShowSuccess(true);
      setTimeout(() => {
        setShowModal(false);
        setFormData({ submitterName: '', quote: '', author: '', username: '', socialLink: '' });
        setShowSuccess(false);
      }, 1500);
      return;
    }

    if (!validateLength(formData.quote)) {
      alert('Quote must be between 2 and 500 characters');
      return;
    }

    if (!validateLength(formData.author)) {
      alert('Author name must be between 2 and 500 characters');
      return;
    }

    if (formData.submitterName && !validateLength(formData.submitterName)) {
      alert('Submitter name must be between 2 and 500 characters');
      return;
    }

    // Format the URL if it starts with www.
    let formattedSocialLink = formData.socialLink;
    if (formData.socialLink && formData.socialLink.startsWith('www.')) {
      formattedSocialLink = `http://${formData.socialLink}`;
    }

    if (formData.socialLink && !validateUrl(formattedSocialLink)) {
      alert('Please enter a valid URL starting with http:// or https://');
      return;
    }

    setIsSubmitting(true);
    
    try {
      const { data, error } = await supabase
        .from('Submissions')
        .insert([
          {
            submitter_name: formData.submitterName || 'Anonymous',
            quote: formData.quote,
            quote_author: formData.author,
            social_link: formattedSocialLink
          }
        ]);

      if (error) throw error;
      
      setShowSuccess(true);
      
      // Wait for success check to show, then close modal
      setTimeout(() => {
        handleCloseModal();
        // Reset form and success state after modal close animation
        setTimeout(() => {
          setFormData({ submitterName: '', quote: '', author: '', username: '', socialLink: '' });
          setShowSuccess(false);
        }, 200); // Match this with modal close animation duration
      }, 1000); // Show success check for 1 second

    } catch (error) {
      console.error('Error submitting quote:', error);
      alert('Failed to submit quote. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setShowModal(false);
      setIsClosing(false);
    }, 200); // Match this with animation duration
  };

  const handleAuthorClick = () => {
    if (quoteData?.social_link) {
      window.open(quoteData.social_link, '_blank', 'noopener noreferrer');
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (showModal || isTyping || isBouncing) return;
      
      if (e.code === 'Space') {
        setIsBouncing(true);
        setTimeout(() => setIsBouncing(false), 300);
      }
    };

    window.addEventListener('keypress', handleKeyPress);
    return () => window.removeEventListener('keypress', handleKeyPress);
  }, [isTyping, isBouncing, showModal]);

  return (
    <div className="App">
      <div className="header-content">
        <div style={{ opacity: showUI ? 1 : 0 }}>
          <h1>quotelog #{calculateDaysSince()}</h1>
          <p className="update-time">updates 9am EST</p>
        </div>
        <button 
          className="submit-button" 
          style={{ opacity: showUI ? 1 : 0 }}
          onClick={() => setShowModal(true)}
        >
          Submit a quote
        </button>
      </div>
      <main className="quote-container">
        <blockquote>
          <p className={`quote-text ${isTyping ? 'typing' : ''} ${isBouncing ? 'bounce' : ''}`}>
            {displayText}
          </p>
          <footer 
            className="quote-author"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={handleAuthorClick}
            style={{ cursor: quoteData?.social_link ? 'pointer' : 'default' }}
          >
            <span className={`author-text ${isHovering ? 'fade-out' : 'fade-in'}`}>
              {displayAuthor}
            </span>
            {quoteData && (
              <span className={`submitter-text ${isHovering ? 'fade-in' : 'fade-out'}`}>
                - Submitted by: {quoteData.submitter_name || 'Anonymous'}
              </span>
            )}
          </footer>
        </blockquote>
      </main>
      <footer className="date-footer" style={{ opacity: showUI ? 1 : 0 }}>
        {getCurrentDate()}
      </footer>
      
      {showModal && (
        <div className={`modal-overlay ${isClosing ? 'closing' : ''}`}>
          <div className={`modal ${isClosing ? 'closing' : ''}`}>
            <button 
              className="close-button" 
              onClick={handleCloseModal}
            >×</button>
            <h2>Submit a quote</h2>
            <form onSubmit={handleSubmit}>
              <div className="hidden" style={{ display: 'none' }}>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  tabIndex="-1"
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                <label htmlFor="quote">Quote*</label>
                <textarea
                  id="quote"
                  name="quote"
                  value={formData.quote}
                  onChange={handleChange}
                  required
                  placeholder="Enter the quote"
                  maxLength={500}
                  minLength={2}
                />
                <small className="character-count">
                  {formData.quote.length}/500
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="author">Who Said It*</label>
                <input
                  type="text"
                  id="author"
                  name="author"
                  value={formData.author}
                  onChange={handleChange}
                  required
                  placeholder="Enter the quote's author (can be you)"
                />
              </div>
              <div className="form-group">
                <label htmlFor="submitterName">Your Name (optional)</label>
                <input
                  type="text"
                  id="submitterName"
                  name="submitterName"
                  value={formData.submitterName}
                  onChange={handleChange}
                  placeholder="Anonymous"
                />
              </div>
              <div className="form-group">
                <label htmlFor="socialLink">External Link (optional)</label>
                <input
                  type="url"
                  id="socialLink"
                  name="socialLink"
                  value={formData.socialLink}
                  onChange={handleChange}
                  placeholder="https://..."
                  pattern="https?://.*"
                />
                {formData.socialLink && !validateUrl(formData.socialLink) && (
                  <small className="error-text">Please enter a valid URL starting with http:// or https://</small>
                )}
              </div>
              
              <button 
                type="submit" 
                className="submit-form-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="spinner"></div>
                ) : showSuccess ? (
                  <div className="success-check">✓</div>
                ) : (
                  'Submit'
                )}
              </button>
              <p className="submission-note">
                All submissions are reviewed and have an equal selection chance.
              </p>
            </form>
          </div>
        </div>
      )}
      <Analytics />
    </div>
  );
}

export default App;